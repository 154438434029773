<template>
  <div id="userOrder">
    <Dialog ref="dialog" :config="config">
      <div class="remittanceScreenshotsBox">
        <div class="remittanceScreenshots">
          <el-image :src="checkImg" v-if="checkImg">
            <div slot="error" class="image-slot">
              <img class="morenflex" src="../../../../../assets/icon/morenfile.svg" alt="" />
            </div>
            <div slot="placeholder">
              <i class="el-icon-loading"></i>
            </div>
          </el-image>
        </div>
        <span class="downLoadImg" @click="
          downLoadImg(
            checkImg,
            checkImg.substr(checkImg.lastIndexOf('/') + 1)
          )
          ">
          <img src="../../../../../assets/user-img/commodity/down.svg" alt="" />
          {{ $fanyi('下载') }}
        </span>
      </div>
    </Dialog>
    <div>
      <div class="searchBox" v-loading.fullscreen.lock="imgloading" :element-loading-text="$fanyi('加载中')">
        <el-date-picker v-model="value1" type="datetimerange" :range-separator="$fanyi('')"
          :start-placeholder="$fanyi('开始日期')" :end-placeholder="$fanyi('结束日期')" value-format="yyyy-MM-dd HH:mm:ss"
          :default-time="['00:00:00', '23:59:59']">
        </el-date-picker>
        <button class="searchBtn">{{ $fanyi('搜索') }}</button>

        <!-- <span class="exportBtn"
          ><img
            src="../../../../../assets/user-img/commodity/export.svg"
            alt=""
          />
          {{ $fanyi("导出excel") }}</span
        > -->

        <span class="exportBtn" @click="$fun.toPage('/user/churujinList')">
          {{ $fanyi('返回') }}</span>
      </div>
      <div class="dataBody">
        <el-table :data="tableData" border :header-cell-style="{
          'text-align': 'center',
          'font-size': '14px ',
          color: 'black',
          background: '#F0F0F0',
        }" :cell-style="{
  'text-align': 'center',
}">
          <el-table-column type="index" width="50" label="No">
          </el-table-column>
          <el-table-column property="created_at" :label="$fanyi('发起时间')" width="180">
            <template slot="header" slot-scope="scope">
              <div class="tableHead" :title="$fanyi('发起时间')">
                {{ $fanyi('发起时间') }}
              </div>
            </template>
          </el-table-column>
          <el-table-column :label="$fanyi('流水号')" width="200">
            <template slot="header" slot-scope="scope">
              <div class="tableHead" :title="$fanyi('流水号')">
                {{ $fanyi('流水号') }}
              </div>
            </template>
            <template slot-scope="scope">
              <div class="liushuihao" :title="$fanyi('流水号')">
                {{ scope.row.serial_number }}
              </div>
            </template>
          </el-table-column>
          <el-table-column property="client_name" :label="$fanyi('收款户名')" width="100">
            <template slot="header" slot-scope="scope">
              <div class="tableHead" :title="$fanyi('收款户名')">
                {{ $fanyi('收款户名') }}
              </div>
            </template>
          </el-table-column>
          <el-table-column property="client_account" :label="$fanyi('收款户号')" width="100">
            <template slot="header" slot-scope="scope">
              <div class="tableHead" :title="$fanyi('收款户号')">
                {{ $fanyi('收款户号') }}
              </div>
            </template>
          </el-table-column>
          <el-table-column property="client_amount" :label="$fanyi('提现金额')" width="100">
            <template slot="header">
              <div class="tableHead" :title="$fanyi('提现金额')">
                {{ $fanyi('提现金额') }}
              </div>
            </template>
            <template slot-scope="scope">
              <div>${{ $fun.EURNumSegmentation(scope.row.client_amount) }}</div>
            </template>
          </el-table-column>
          <el-table-column property="status" :label="$fanyi('处理状态')" width="110">
            <template slot="header" slot-scope="scope">
              <div class="tableHead" :title="$fanyi('处理状态')">
                {{ $fanyi('处理状态') }}
              </div>
            </template>
            <template slot-scope="scope">
              <div>
                {{ $fanyi(scope.row.status) }}
              </div>
            </template>
          </el-table-column>
          <el-table-column property="refuse_reason" :label="$fanyi('明细')">
            <template slot="header" slot-scope="scope">
              <div class="tableHead" :title="$fanyi('明细')">
                {{ $fanyi('明细') }}
              </div>
            </template>
          </el-table-column>
          <el-table-column property="date" :label="$fanyi('其他')" width="90px">
            <template slot="header" slot-scope="scope">
              <div class="tableHead" :title="$fanyi('其他')">
                {{ $fanyi('其他') }}
              </div>
            </template>
            <template slot-scope="scope">
              <div>
                <span class="CheckVoucher" @click="
                  checkImg = scope.row.pay_image
                checkVoucher()
                  " v-if="scope.row.pay_image &&
    ['.jpg', '.png', '.jpeg'].indexOf(
      scope.row.pay_image.substr(
        scope.row.pay_image.lastIndexOf('.')
      )
    ) != -1
    ">
                  {{ $fanyi('查看凭证') }}
                </span>
                <span class="CheckVoucher" v-else-if="scope.row.pay_image">
                  <a :href="scope.row.pay_image" style="color: #1e2997" :download="scope.row.pay_image.substr(
                    scope.row.pay_image.lastIndexOf('/') + 1
                  )
                    ">
                    {{ $fanyi('查看凭证') }}</a>
                </span>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="allpag">
        <!-- <span class="backHome" href="javascript:;" @click="
          form.page = 1
          getData()
        ">{{ $fanyi('回到首页') }}</span> -->
        <el-pagination @current-change="handleCurrentChange" :current-page="form.currentPage" :page-size="10"
          :page-sizes="[10, 20, 30, 50]" layout=" prev, pager, next, jumper" :total="total">
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { DOWLOAD_FILE } from '@/utlis/dowload'
import Dialog from '../../../../../components/public/Dialog'

import {
  getCurrentMonthFirst,
  getCurrentMonthLast,
} from '../../../../../utlis/date'
export default {
  data() {
    return {
      value1: [],
      form: {
        status: 'temporary',
        page: 1,
        pageSize: 10,
        searchText: '',
        startTime: '',
        endTime: '',
      },
      tableData: [
        {
          date: 'dsfad',
        },
      ],
      imgloading: false,
      total: 0,
      checkImg: '', //查看凭证的图片
      tableOption: [], //表格显示哪些选项
      ordersSumList: {}, //商品列表各种类商品数据
      config: {
        top: '20vh',
        width: '480px',
        title: this.$fanyi('汇款截图'),
        btnTxt: [this.$fanyi('关闭')],
      },
    }
  },
  created() {
    this.getData()
  },
  methods: {
    getData() {
      this.$api
        .capitalCashOutList({
          pageSize: this.form.pageSize,
          startTime: this.value1 ? this.value1[0] : '',
          endTime: this.value1 ? this.value1[1] : '',
          page: this.form.page,
        })
        .then((res) => {

          if (res.code != 0) return

          this.tableData = res.data.data
          this.total = res.data.total
        })
    },
    // 查看凭证
    checkVoucher() {
      this.$refs.dialog.open()
    },

    downLoadImg(data, fileName) {
      this.imgloading = true
      this.$api
        .downloadFile({
          file_url: data,
        })
        .then((res) => {
          this.imgloading = false
          this.downloadFileByBase64(res.data.file_data, fileName)
        })
    },
    dataURLtoBlob(dataurl) {
      var arr = dataurl.split(','),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n)
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n)
      }
      return new Blob([u8arr], { type: mime })
    },
    //下载方法
    downloadFile(url, name = 'imagen') {
      var a = document.createElement('a')
      a.setAttribute('href', url)
      a.setAttribute('download', name)
      let clickEvent = document.createEvent('MouseEvents')
      clickEvent.initEvent('click', true, true)
      a.dispatchEvent(clickEvent)
    },
    getBase64Type(type) {
      switch (type) {
        case 'txt':
          return 'data:text/plain;base64,'
        case 'doc':
          return 'data:application/msword;base64,'
        case 'docx':
          return 'data:application/vnd.openxmlformats-officedocument.wordprocessingml.document;base64,'
        case 'xls':
          return 'data:application/vnd.ms-excel;base64,'
        case 'xlsx':
          return 'data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,'
        case 'pdf':
          return 'data:application/pdf;base64,'
        case 'pptx':
          return 'data:application/vnd.openxmlformats-officedocument.presentationml.presentation;base64,'
        case 'ppt':
          return 'data:application/vnd.ms-powerpoint;base64,'
        case 'png':
          return 'data:image/png;base64,'
        case 'jpg':
          return 'data:image/jpeg;base64,'
        case 'gif':
          return 'data:image/gif;base64,'
        case 'svg':
          return 'data:image/svg+xml;base64,'
        case 'ico':
          return 'data:image/x-icon;base64,'
        case 'bmp':
          return 'data:image/bmp;base64,'
      }
    },
    getType(file) {
      var filename = file
      var index1 = filename.lastIndexOf('.')
      var index2 = filename.length
      var type = filename.substring(index1 + 1, index2)
      return type
    },
    downloadFileByBase64(base64, name) {
      var myBlob = this.dataURLtoBlob(base64)
      var myUrl = URL.createObjectURL(myBlob)
      this.downloadFile(myUrl, name)
    },

    handleCurrentChange(val) {
      this.form.page = val
      this.getData()
    },
  },
  components: { Dialog },
  computed: {},
}
</script>
<style lang="scss" scoped="scoped">
#userOrder {
  @import '../../../../../css/mixin';
  width: 1060px;
  border-radius: 6px;

  button {
    cursor: pointer;
  }

  /deep/ .el-button {
    border-radius: 6px !important;
  }

  /deep/ .el-date-editor {
    width: 344px;
    height: 40px;
    background: #ffffff;
    border-radius: 4px;
    border: 1px solid #e2e2e2;

    .el-input__icon {
      display: none;
    }
  }

  .liushuihao {
    font-size: 14px;
    font-family: PingFang-SC-Regular, PingFang-SC;
    font-weight: 400;
    // color: #ff730b;
    // &:hover {
    //   text-decoration: underline;
    // }
  }

  /deep/.el-range-separator {
    width: 3px;
    height: 20px;
    // background-color: pink;
    background: url('../../../../../assets/date.png') no-repeat center;
  }

  /deep/ .el-pagination__jump,
  /deep/ .el-pagination__total {
    display: none !important;
  }

  >nav {
    height: 50px;
    display: flex;

    >span {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 130px;
      height: 50px;
      background: #e8e8e8;
      border: 1px solid #e1e1e1;
      font-size: 16px;

      color: #000000;
      line-height: 26px;

      cursor: pointer;

      &.active {
        background-color: #ffffff;
        border-bottom: none;
      }
    }
  }

  >div {
    // min-height: 720px;
    background: #ffffff;
    border: 1px solid #e1e1e1;
    border-top: none;
    padding: 20px 20px 20px 20px;

    .searchBox {
      display: flex;
      margin-bottom: 20px;
      align-items: center;

      /deep/.el-date-editor {
        // width: 230px;
        height: 40px;
        margin-right: 20px;
        border-radius: 4px;

        .el-range-input {
          font-size: 14px;
        }

        .el-range-separator {
          width: 50px;
        }
      }

      .searchBtn {
        width: 80px;
        height: 40px;
        background: $homePageSubjectColor;
        border-radius: 4px;

        color: #ffffff;
        font-size: 16px;
      }

      >span {
        font-size: 14px;
        cursor: pointer;
        line-height: 19px;
      }

      .exportBtn {
        width: 140px;
        height: 40px;
        border-radius: 4px;
        border: 1px solid #ff730b;
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        display: flex;
        color: #ff730b;
        align-items: center;
        justify-content: center;
        line-height: 40px;
        margin-left: auto;

        img {
          margin-right: 10px;
          width: 15px;
          height: 15px;
        }
      }
    }

    .dataBody {
      .CheckVoucher {
        font-size: 14px;
        font-family: PingFang-SC-Regular, PingFang-SC;
        font-weight: 400;
        color: #698cff;

        line-height: 20px;

        cursor: pointer;
      }
    }

    .allpag {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 25px 0;

      .el-pagination {
        display: flex;
        align-items: center;
      }

      >a {
        display: inline-block;
        border: 1px solid #dcdfe6;
        height: 28px;
        // background-color: white;
        text-align: center;
        padding: 0 10px;
        font-size: 13px;
        line-height: 28px;
        border-radius: 0;
      }

      /deep/.btn-prev {
        width: 40px;
        height: 40px;
        background: #f5f5f5;
        border-radius: 20px;
        padding: 0;
        margin-right: 30px;

        .el-icon {
          font-size: 15px;
        }
      }

      /deep/.btn-next {
        width: 40px;
        height: 40px;
        background: #f5f5f5;
        border-radius: 20px;
        padding: 0;
        margin-left: 30px;

        .el-icon {
          font-size: 15px;
        }
      }

      /* 分页被选中的页码样式 */
      /deep/ .el-pager li {
        margin: 0 10px;
        // border-radius: 0;
        min-width: 20px !important;
        font-size: 14px;
        height: 30px;
        color: #999999;
        // box-shadow: 0 0 6px 0 rgba(61, 61, 61, 0.1);

        &:hover {
          color: #ff730b;
        }

        &.active {
          color: #ff730b;
          border-bottom: 2px solid #ff730b;
          width: 20px !important;
        }
      }
    }
  }
}

/deep/.el-dialog__body {
  padding: 30px 20px 12px !important;
}

/deep/.el-image {
  i {
    &::before {
      width: 50px;
    }
  }
}

// 汇款截图
.remittanceScreenshotsBox {
  .remittanceScreenshots {
    width: 400px;
    min-height: 240px;
    overflow: auto;
    background: rgba(30, 41, 151, 0.05);
    border-radius: 10px;
    border: 1px solid #1e2997;
    margin: 0 auto 30px;
    display: flex;
    justify-content: center;
    align-items: center;

    /deep/.el-image {
      $imgSize: 100%;
      width: $imgSize;
      transition: 0.3s;
      text-align: center;

      .morenflex {
        width: 380px;
      }

      i {
        font-size: $imgSize/4;
        line-height: $imgSize;
      }
    }
  }

  .downLoadImg {
    font-size: 16px;

    color: #ffa724;
    line-height: 18px;

    display: flex;
    width: 400px;
    margin: 0 auto;
    justify-content: flex-end;
    align-items: center;
    cursor: pointer;

    img {
      width: 24px;
      height: 20px;
      margin-right: 5px;
    }
  }
}

.tableHead {
  line-height: 14px;
  font-size: 12px;
}
</style>
